import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Linear',
  templateUrl: './Linear.component.html',
  styleUrls: ['./Linear.component.css']
})
export class LinearComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
