import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageFields } from 'src/app/enums/LocalStorageFields.enum';
import { ClipboardService } from 'ngx-clipboard';
import { ToastService } from 'src/app/services/Toast.service';
import { Config } from 'src/app/enums/Config';

@Component({
  selector: 'app-user-view-panel',
  templateUrl: './user-view-panel.component.html',
  styleUrls: ['./user-view-panel.component.css']
})
export class UserViewPanelComponent implements OnInit {

  user: any;
  constructor(
    private _clipboardService: ClipboardService,
    private _toast: ToastService
  ) { }

  ngOnInit() {
    let json = localStorage.getItem(LocalStorageFields.user);
    this.user = JSON.parse(json);
  }

  copiIniteCode() : void {
    this._clipboardService.copyFromContent(this.user.inviteCode);
    this._toast.dropPrimary("Meghívó kód sikeresen kimásolva a vágólapra.");
  }

  copyInviteLink() : void {
    const url = Config.frontEnd_url_localhost + "/?invite=" + this.user.inviteCode;
    this._clipboardService.copyFromContent(url);
    this._toast.dropPrimary("Meghívó link sikeresen kimásolva a vágólapra.");
  }
}
