import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/Api/Auth.service';
import { Roles } from '../enums/Roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    private _auth: AuthService,
    private _router: Router
  ) {}

  canActivate(): boolean {
    if(! this._auth.loggedIn()) {
      this._router.navigate(["/user-area"]);
      return false;
    }

    if(this._auth.decodedToken.role === Roles.Admin) {
      return true;
    }

    this._router.navigate(["/user-area"]);
    return false;
  }
  
}
