import { Component, OnInit } from '@angular/core';
import { ToastService } from '../services/Toast.service';
import { States } from '../enums/States';
import { IRegistrationRequest } from '../models/IRegistrationRequest';
import { AuthService } from '../services/Api/Auth.service';
import { RedirectService } from '../services/Redirect.service';

@Component({
  selector: 'app-RegistrationPage',
  templateUrl: './RegistrationPage.component.html',
  styleUrls: ['./../landingpage/landingpage.component.css', './RegistrationPage.component.css']
})
export class RegistrationPageComponent implements OnInit {
  state: States = States.base;
  states: any = States;

  showPasswordInput: boolean = false;
  validRegKeyInput: boolean = false;

  request: IRegistrationRequest = {} as IRegistrationRequest;

  constructor(
    private _toast: ToastService,
    private _authService: AuthService,
    private _redirec: RedirectService
    ) { }

  ngOnInit() {
  }

  validationRegKey(event: any) {
    this.state = States.load;
    this._authService.checkValidRegKey(this.request.RegistrationKey)
      .subscribe((result: any) => { 
        setTimeout(() => {
          this.state = States.done;
          this.validRegKeyInput = true;
          this._toast.dropSuccess("Érvényes regisztrációs kulcs!");
        }, 3000); 
      }, (error: any) => { 
        setTimeout(() => {
          this.state = States.base;
          this._toast.dropDanger("Érvénytelen regisztrációs kulcs!");
        }, 3000);
      });
  }

  registration() {
    this.state = States.load;
    this._authService.registration(this.request)
      .subscribe((result) => {
        setTimeout(() =>{
          this._toast.dropSuccess("Sikeres regisztáció, most már bejelentkezhetsz.");
          this._redirec.ToLanding();
        }, 3500);        
      }, (error) => {
        setTimeout(() => {
          this.state = States.base;
          this._toast.dropDanger("Sikertelen felhasználó regisztráció!");
          console.error(error);
        }, 3500);         
      });
  }
}
