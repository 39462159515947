import { Component, OnInit } from '@angular/core';
import { PreRegistratedUserService } from 'src/app/services/Api/PreRegistratedUser.service';
import { ToastService } from 'src/app/services/Toast.service';
import { IPreRegistratedUser } from 'src/app/models/IPreRegistratedUser';
import { ClipboardService } from 'ngx-clipboard';
import { AuthService } from 'src/app/services/Api/Auth.service';

@Component({
  selector: 'app-PreRegistratedUsers',
  templateUrl: './PreRegistratedUsers.component.html',
  styleUrls: ['./PreRegistratedUsers.component.css']
})
export class PreRegistratedUsersComponent implements OnInit {
  preRegistratedUserList: IPreRegistratedUser[];

  constructor(
    private _preRegistratedUserService: PreRegistratedUserService,
    private _toast: ToastService,
    private _clipboardService: ClipboardService,
    private _auth: AuthService
    ) { }

  ngOnInit() {
    this.fetchAll();
  }

  copyToClipboard(key: string) : void {
    this._clipboardService.copyFromContent(key);
    this._toast.dropSuccess("Vágólapra másolva.")
  }

  enabled(user: IPreRegistratedUser) {
    this._auth.enabled(user)
      .subscribe((response) => 
      { 
        this.fetchAll();
        this._toast.dropSuccess("Sikeresen engedélyezve a regisztráció!");
      }, (error) => { console.error(error) });
  }

  fetchAll() : void {
    this._preRegistratedUserService.getAll()
      .subscribe((result) => { 
        this.preRegistratedUserList = result;
      }, (error) => { 
        this._toast.dropDanger("Előregisztrált felhasználók lekérdezése sikertelen!"); 
      });
  }

}
