import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/services/Api/Auth.service';
import { WalletService } from 'src/app/services/Api/wallet.service';
import { ToastService } from 'src/app/services/Toast.service';
import { User } from 'src/app/models/User';
import { WalletCollection } from 'src/app/models/collections';
import { ActivatedRoute } from '@angular/router';
import { MaterializeInitService } from 'src/app/services/materialize-init.service';
import * as M from 'materialize-css';
import { Wallet } from 'src/app/models/Wallet';
import { CurrencyTypes } from 'src/app/enums/CurrencyTypes.enum';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css'],
  providers: [
    AuthService, 
    WalletService, 
    ToastService, 
    MaterializeInitService, 
    AlertifyService
  ]
})
export class WalletComponent implements OnInit, AfterViewInit {
  
  showTermsOfUseAndRules: boolean = false;
  showWallets: boolean = false;
  wallets: WalletCollection = [];
  modal: any;

  @ViewChild('acceptCard', { static: true }) acceptCard: ElementRef<HTMLDivElement>;
  @ViewChild('accepted', {static: false}) accepted: ElementRef<HTMLInputElement>;

  constructor(
    private _auth: AuthService, 
    private _walletService: WalletService,
    private _toast: ToastService,
    private _route: ActivatedRoute,
    private _materialize: MaterializeInitService,
    private _alertify: AlertifyService
    ) { }

  ngOnInit() {    
    this.setShow();
    this.loadData();
  }

  selectWallet(currencyType: string): Wallet {
    let res = this.wallets.filter((wallet: Wallet) => {
      return wallet.currencyType === currencyType;
    });

    return res[0];
  }

  currentAmountTCr(): number {
    let wallet = this.selectWallet(CurrencyTypes.TCr);
    return wallet.balance;
  }

  transferAmountChange(event: number): void {
    this.loadWalletsData(this._auth.CurrentUser());
    // let wallet = this.selectWallet(CurrencyTypes.TCr);
    // wallet.balance -= event;
  }

  ngAfterViewInit(): void {
    this._materialize.Dropdown();
  }

  private loadData(): void {
    this._route.data.subscribe(data => {
      this.wallets = data['wallets'];
      this.showWallets = true;
    })
  }

  confirm() {
    this._alertify.confirm("hello confirm?", () => {});
  }

  private getByUser(user: User) : void {
    if(this._auth.CurrentUser().enableWallets) {
      this.loadWalletsData(user);
    }  
  }

  loadWalletsData(user: User): void {
    this._walletService.GetByUser(user)
    .subscribe((response: WalletCollection) => {
      this.wallets = response;
      this.showWallets = true;          
    }, error => this._toast.dropDanger(error.message))
  }

  private setShow() : void {
    this.showTermsOfUseAndRules = this._auth.CurrentUser().enableWallets;
  }

  CreatedWallets() : void {
    if(! this.accepted.nativeElement.checked) {
      this._toast.dropPrimary('Jelöld ki az "Megértettem" checkbox-ot!');
      return null;
    }

    if(this.showTermsOfUseAndRules) {
      return null;
    }

    this._walletService.CreateWallets()
      .subscribe((response) => {
        let user: User = this._auth.CurrentUser();
        user.enableWallets = true;
        this._auth.Persistent(user);
        this.showTermsOfUseAndRules = !this.showTermsOfUseAndRules;
        this.getByUser(this._auth.CurrentUser())
      }, error => this._toast.dropDanger(error.message));
  }

}
