import { Injectable } from '@angular/core';
import { ContextualColorClasses } from '../enums/ContextualColorClasses';
import * as M from 'materialize-css';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private displayLength: number = 5500;

  dropSuccess(text: string) :void {
    M.toast({
      html: text,
      displayLength: this.displayLength,
      classes: ContextualColorClasses.success
    });
  }

  dropDanger(text: string) :void {
    M.toast({
      html: text,
      displayLength: this.displayLength,
      classes: ContextualColorClasses.danger
    });
  }

  dropPrimary(text: string) :void {
    M.toast({
      html: text,
      displayLength: this.displayLength,
      classes: ContextualColorClasses.primary
    });
  }

}
