import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-UserArea',
  templateUrl: './UserArea.component.html',
  styleUrls: ['./UserArea.component.css']
})
export class UserAreaComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit() {
  }

}
