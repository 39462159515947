import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BuyTCrMoneyTransferRequest } from 'src/app/models/Transactions/BuyTCrMoneyTransferRequest';
import { Observable } from 'rxjs';
import { API_ADDRESS } from 'src/app/app.component';
import { TransferRequest } from 'src/app/models/Transactions/TransferRequest';
import { User } from 'src/app/models/User';
import { TCrTransferCollection } from 'src/app/models/collections';
import { map } from 'rxjs/operators';
import { TCrTransfer } from 'src/app/models/Transactions/TCrTransfer';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private _http: HttpClient) { }

  submitBuyTCrTransfer(request: BuyTCrMoneyTransferRequest): Observable<any> {
    return this._http.post(
      API_ADDRESS + "/api/Transactions/buytcrmoneytransfer",
      request
      );
  }

  submitTransfer(request: TransferRequest): Observable<any> {
    return this._http.post(
      API_ADDRESS + "/api/Transactions/transfer",
      request
    );
  }

  

  GetTransferByUser(): Observable<TCrTransferCollection> {
    return this._http.get<TCrTransferCollection>(API_ADDRESS + "/api/Transactions/transfer/user")
      .pipe(map(
        (response: any) => {
          let collection: TCrTransferCollection = [];
            for(let item of response) {
              let transfer = new TCrTransfer(item);
              transfer.from = new User(item.from);
              transfer.to = new User(item.to);
              collection.push(transfer);
            }
          return collection;
        }, (error) => console.error(error)
      ));
  }
}
