import { ITransaction } from './ITransaction';
import { TransactionStates } from 'src/app/enums/TransactionStates.enum';
import { CurrencyTypes } from 'src/app/enums/CurrencyTypes.enum';
import { User } from '../User';

export class TCrTransfer implements ITransaction {
    id?: number;
    amount: number;
    from: User;
    to: User;
    note: string;
    currency: CurrencyTypes;
    state: TransactionStates;
    creationTime: Date;

    constructor(transfer: TCrTransfer = null) {
        if(transfer !== null) {
            Object.assign(this, transfer);

            this.creationTime = new Date(transfer.creationTime);
        }
    }
}