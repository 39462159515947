import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-Circular',
  templateUrl: './Circular.component.html',
  styleUrls: ['./Circular.component.css']
})
export class CircularComponent implements OnInit {

  // "red", "green", default: "blue"
  @Input() color: string = "blue";
  
  // "big", "small", default: "" === medium
  @Input() size: string = "";

  constructor() { }

  ngOnInit() {
  }

}
