import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleStorageService } from 'src/app/services/Api/article-storage.service';
import { TagCollection } from 'src/app/models/collections';
import { IArticle } from 'src/app/models/IArticle';
import { RedirectService } from 'src/app/services/Redirect.service';
import { Article } from 'src/app/models/Article';
import { TagStorageService } from 'src/app/services/Api/tag-storage.service';
import { ToastService } from 'src/app/services/Toast.service';
import { IChip } from 'src/app/models/IChip';
import { Tag } from 'src/app/models/Tag';
import * as M from 'materialize-css';

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.css'],
  providers: [
    ArticleStorageService, 
    RedirectService, 
    TagStorageService, 
    ToastService
  ]
})
export class EditArticleComponent implements OnInit {
  article: Article = new Article();
  show: boolean = false;
  private _tagField: M.Chips;

  @ViewChild("articleTitleInput", {static: true}) articleTitleInput: ElementRef<HTMLInputElement>;
  @ViewChild("articlePreviewTextarea", {static: true}) articlePreviewTextarea: ElementRef<HTMLTextAreaElement>;
  @ViewChild("articleBodyTextarea", {static: true}) articleBodyTextarea: ElementRef<HTMLTextAreaElement>;
  @ViewChild('tagInputField', { static: true }) tagInputField: ElementRef;
  
  constructor(
    private _articleStrorage: ArticleStorageService,
    private _route: ActivatedRoute,
    private _redirect: RedirectService,
    private _tagStorage: TagStorageService,
    private _toast: ToastService
  ) { }

  ngOnInit() {    
    const slug = this._route.snapshot.paramMap.get('slug');
    this.getArticle(slug);
  }

  getArticle(slug: string) : void {
    this._articleStrorage.GetBySlug(slug)
      .subscribe((result: Article) => {
        this.article = result;
        this.show = true;
        this.handlingTextFields(this.article);
        this._initTagInputField(this.article.tags);
      }, (error) => { console.error(error); });
  }

  private _initTagInputField(tagList: TagCollection) : void {
    this._tagStorage.GetAll()
      .subscribe((response: TagCollection) => {
        var elems = document.querySelectorAll('.chips');

        let data: any = {};
        for(let tag of response as TagCollection) {
          data[tag.name] = null;
        }

        M.Chips.init(elems, {
          autocompleteOptions: {
            data: data,
            limit: Infinity,
            minLength: 1
          },
          placeholder: 'Adj meg címkéket! ...',
          secondaryPlaceholder: '+Címke'
        });
        this._tagField = M.Chips.getInstance(this.tagInputField.nativeElement);
    
        for(const tag of tagList) {
          this._tagField.addChip({
            tag: tag.name
          });
        }
      }, (error) => { 
        this._toast.dropDanger("Címkék lekérdezése nem sikerült, autocomplete nem működik.");
        console.error(error);
       });   
  }

  private handlingTextFields(article: IArticle) : void {
    this.articlePreviewTextarea.nativeElement.value = article.preview;
    M.textareaAutoResize(this.articlePreviewTextarea.nativeElement);
    this.articleBodyTextarea.nativeElement.value = article.body;
    M.textareaAutoResize(this.articleBodyTextarea.nativeElement);
    M.updateTextFields();
    this.articleTitleInput.nativeElement.nextElementSibling.classList.add("active");    
  }

  Cancel() : void {
    this._redirect.ToArticle(this.article.slug);
  }

  private _addTags(article: Article) : void {
    article.tags = [];
    for(let chip of this._tagField.chipsData as IChip[]) {
      this.article.tags.push(new Tag({ name: chip.tag }));
    }
  }

  update() : void {
    this._addTags(this.article);
    this._articleStrorage.Update(this.article)
      .subscribe(response => {
        this._toast.dropSuccess("Cikk sikeresen módosítva");
        this._redirect.ToArticles();
      }, error => {
        this._toast.dropDanger("Cikk módosítása sikertelen");
      })
  }
}
