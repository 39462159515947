import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-ButtonCircle',
  templateUrl: './ButtonCircle.component.html',
  styleUrls: ['./ButtonCircle.component.css']
})
export class ButtonCircleComponent implements OnInit {
  
  @Input() buttonText: string;

  @Input() icon_material: string = "";
  @Input() icon_font_awesome: string = "";

  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  constructor() {
    
  }

  ngOnInit() {
    
  }

  buttonClickEvent() {
    this.buttonClick.emit(true);
  }

}
