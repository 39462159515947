import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SettingsService } from 'src/app/services/Api/settings.service';
import { ActivatedRoute } from '@angular/router';
import { ISettings } from 'src/app/models/ISettings';
import { MClasses } from 'src/app/enums/MClasses.enum';
import { ToastService } from 'src/app/services/Toast.service';
import { RedirectService } from 'src/app/services/Redirect.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  providers: [SettingsService, ToastService, RedirectService]
})
export class SettingsComponent implements OnInit, AfterViewInit {
  settings: ISettings;

  @ViewChild("tCrMinimalBuyingAmmount", {static: true}) tCrMinimalBuyingAmmount: ElementRef<HTMLInputElement>
  @ViewChild("btcAccountAddress", {static: true}) btcAccountAddress: ElementRef<HTMLInputElement>
  @ViewChild("defaultCurrency", {static: true}) defaultCurrency: ElementRef<HTMLInputElement>
  @ViewChild("tCrPrice", {static: true}) tCrPrice: ElementRef<HTMLInputElement>
  
  @ViewChild('settingsForm', {static: true}) Form: NgForm;

  constructor(
    private _settingsService: SettingsService,
    private _route: ActivatedRoute,
    private _toast: ToastService,
    private _redirect: RedirectService
    ) { }

  ngOnInit() {
    this.loadData();
    this.initFormInputLabels();
  }

  ngAfterViewInit(): void {
    M.Range.init(this.tCrMinimalBuyingAmmount.nativeElement);
  }

  initFormInputLabels(): void {
    this.btcAccountAddress.nativeElement.nextElementSibling.classList.add(MClasses.active);
    this.defaultCurrency.nativeElement.nextElementSibling.classList.add(MClasses.active);
    this.tCrPrice.nativeElement.nextElementSibling.classList.add(MClasses.active);
  }

  loadData(): void {
    this._route.data.subscribe(data => {
      this.settings = data["settings"];
    });

  }

  save(): void {
    console.log(this.settings);
    this._settingsService.Create(this.settings)
      .subscribe(response => {
        this._toast.dropSuccess("Beállítások sikeresen elmentődtek!");
        this._redirect.Reload();
      }, error => {
        this._toast.dropDanger("Sikertelen mentés!");
      });
  }
}
