import { Component, OnInit } from '@angular/core';
import * as M from 'materialize-css'
import { TransactionService } from 'src/app/services/Api/transaction.service';
import { TCrTransferCollection } from 'src/app/models/collections';
import { ToastService } from 'src/app/services/Toast.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
  providers: [TransactionService, ToastService]
})
export class TransactionsComponent implements OnInit {
  private _tabs: M.Tabs[];
  tcrTransferList: TCrTransferCollection = [];

  constructor(
    private _transactionService: TransactionService, 
    private _toast: ToastService
    ) { }

  private _initTabs(): M.Tabs[] {
    var tabsEl = document.querySelectorAll(".tabs");
    return M.Tabs.init(tabsEl);
  }

  ngOnInit() {
    this.loadData(this.afterLoad);
    this._tabs = this._initTabs();
  }

  afterLoad = (response) => {
    this.tcrTransferList = response;
    console.log(response);
  };

  loadData(loadCallback: (response) => void): void {
    this._transactionService.GetTransferByUser()
      .subscribe((response: TCrTransferCollection) => {
        loadCallback(response);
      }, (error) => {
        console.error(error);
        this._toast.dropDanger("Sikertelen adatlekérdezés")
      });
  }

}
