import { IProduct } from './IProduct';

export class Book implements IProduct {
    title: string;    
    isShow: boolean;
    productPrice: number;
    productImage: string;
    productYoutubeVideo: string;
    productDescription: string;
    creationTime: Date;
    slug: string;
    stock: number;
    author: string;

    constructor(book: Book = null) {
        if(book !== null) {
            Object.assign(this, book);

            this.creationTime = new Date(book.creationTime);
        }
    }
}
