import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/Api/Auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {

  constructor(
    private _auth: AuthService,
    private _router: Router
  ) {}

  canActivate(): boolean {
    if(this._auth.loggedIn()) {
      return true;
    }

    this._router.navigate(["/"]);
    return false;
  }
  
}
