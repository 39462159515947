import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BookCollection } from 'src/app/models/collections';
import { API_ADDRESS } from 'src/app/app.component';
import { map } from 'rxjs/operators';
import { Book } from 'src/app/models/Products/Book';
import { Observable } from 'rxjs';
import { ToastService } from '../Toast.service';
import { AjaxCallback } from 'src/app/types';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(private _http: HttpClient, private _toast: ToastService) { }

  GetBooks() : Observable<BookCollection> {
    return this._http.get<BookCollection>(API_ADDRESS + "/api/Books")
      .pipe(
        map((response: BookCollection) => {
          let resp: BookCollection = [];
            for(let book of response) {
              resp.push(new Book(book));
            }
          return resp;
        })
      );
  }

  CreateBook(book: Book, callback: AjaxCallback): void {
    this._http.post(API_ADDRESS + "/api/Books", book)
      .subscribe((callback), 
      (error: any) => {console.error(error); this._toast.dropDanger(error);});
  }
}
