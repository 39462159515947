import { Injectable } from '@angular/core';
import { AuthorizeService } from './Authorize.service';
import { Observable, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_ADDRESS } from 'src/app/app.component';
import { map } from 'rxjs/operators';
import { ArticleCollection } from 'src/app/models/collections';
import { Article } from 'src/app/models/Article';

@Injectable({
  providedIn: 'root'
})
export class ArticleStorageService {

  constructor(
    private _http: HttpClient,
    private _authorize: AuthorizeService,
  ) { }

  Save(article: Article) : Observable<any> {
    return this._http.post<any>(
      API_ADDRESS + "/api/articles", 
      article, 
      { headers: this._authorize.getAuthHeader() }
      );
  }

  Update(article: Article) : Observable<any> {
    return this._http.put(
      API_ADDRESS + "/api/articles/" + article.id,
      article,
      { headers: this._authorize.getAuthHeader() }
      )
  }

  FetchAllPublished() : Observable<ArticleCollection> {
    return this._http.get(
        API_ADDRESS + "/api/Articles/Published",
        { headers: this._authorize.getAuthHeader() }
        )
      .pipe(map((response: any) => {
        let returnData: ArticleCollection =[];
          for(let article of response) {
            let newArticle = new Article(article);  
            returnData.push(newArticle);
          }

        return returnData;
      }, (error) => { console.error(error) }));
  }

  FetchAllUnPublished() : Observable<ArticleCollection> {
    return this._http.get(
        API_ADDRESS + "/api/Articles/UnPublished",
        { headers: this._authorize.getAuthHeader() }
        )
      .pipe(map((response: any) => {
        let returnData: ArticleCollection =[];
          for(let article of response) {
            let newArticle = new Article(article);  
            returnData.push(newArticle);
          }

        return returnData;
      }, (error) => { console.error(error) }));
  }


  GetBySlug(slug: string) : Observable<Article> {
    return this._http.get<Article>(
        API_ADDRESS + "/api/Articles/" + slug,
        { headers: this._authorize.getAuthHeader() }
        )
      .pipe(map((result: any) => {
        result.creationTime = new Date(result.creationTime);
        let newArticle: Article = new Article(result);
      
        return newArticle;
      }, (error) => { console.log(error); }));
  }
}
