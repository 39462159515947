import { Component, OnInit, Input } from '@angular/core';
import { ButtonIconAlignment } from 'src/app/enums/ButtonIconAlignment.enum';
import { IconMaterial } from 'src/app/enums/IconMaterial.enum';
import { ButtonSize } from 'src/app/enums/ButtonSize.enum';
import { Waves } from 'src/app/enums/Waves.enum';
import { ButtonShape } from 'src/app/enums/ButtonShape.enum';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() buttonText: string = "SEND"
  @Input() icon: IconMaterial = IconMaterial.cloud;
  @Input() size: ButtonSize = ButtonSize.regular;
  @Input() shape: ButtonShape = ButtonShape.default;
  @Input() iconAlignment: ButtonIconAlignment = ButtonIconAlignment.left;
  @Input() waves: Waves = Waves.light;
  @Input() title: string = "";
  @Input() setDisabled: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

}
