import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  constructor(private _router: Router) {}

  ToLanding(): void {
    this._router.navigate(["/landing"]);
    //.then(() => { window.location.reload(); });
  }

  ToUserArea(): void {
    this._router.navigate(["/user-area"]);
  }

  ToArticles(): void {
    this._router.navigate(["/user-area/articles"]);
  }

  ToArticle(slug: string): void {
    this._router.navigate(["/user-area/articles", slug]);
  }

  ToSettings(): void {
    this._router.navigate(["/user-area/settings"]);
  }

  Reload(): void {
    location.reload();
  }

  ToTwoFactorAuthentication(): void {
    this._router.navigate(["/user-area/two-factor-authentication"]);
  }
}
