import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/Api/Auth.service';
import { RedirectService } from 'src/app/services/Redirect.service';
import { MaterializeInitService } from 'src/app/services/materialize-init.service';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-Topbar',
  templateUrl: './Topbar.component.html',
  styleUrls: ['./Topbar.component.css'],
  providers: [AuthService, RedirectService, MaterializeInitService]
})
export class TopbarComponent implements OnInit {

  dropDown: M.Dropdown;
  currentUser: User;

  constructor(
    private _auth: AuthService,
    private _redirect: RedirectService,
    private _materlialize: MaterializeInitService
  ) { }


  ngOnInit() {
    this.currentUser = this._auth.CurrentUser();
    this.loggedIn();
    this.dropDown = this._materlialize.DropdownInit(".dropdown-trigger");
  }

  logout() : void {
    this._auth.removeToken();
    this.loggedIn();
  }

  loggedIn() : void {
    if(! this._auth.loggedIn()) {
      this._redirect.ToLanding();
    }
  }

}
