import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommentCollection } from 'src/app/models/collections';
import { API_ADDRESS } from 'src/app/app.component';
import { AuthorizeService } from './Authorize.service';
import { map } from 'rxjs/operators';
import { Comment } from './../../models/Comment';

@Injectable({
  providedIn: 'root'
})
export class CommentStorageService {

  constructor(
    private _http: HttpClient,
    private _authorize: AuthorizeService
  ) { }

  GetAllCommentForArticle(articleId: number) : Observable<CommentCollection> {
    console.log(articleId);
    return this._http.get(
      API_ADDRESS + "/api/Comments/" + articleId,
      { headers: this._authorize.getAuthHeader() }
      )
      .pipe(
        map((response: CommentCollection) => {
          let result: CommentCollection = [];
            for(let comment of response) {
              result.push(new Comment(comment));
            }
          return result;
        }, (error: any) => { console.error(error); })
      )
  }
}
