import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { I18nContext } from '@angular/compiler/src/render3/view/i18n/context';

@Component({
  selector: 'app-button-back-to-previous-page',
  templateUrl: './button-back-to-previous-page.component.html',
  styleUrls: ['./button-back-to-previous-page.component.css']
})
export class ButtonBackToPreviousPageComponent implements OnInit {

  @Input() title: string = "Vissza az előző oldalra!";
  @Input() icon: string = "arrow_back";
  @Input() show: boolean = true;
  constructor(private _location: Location) { }

  ngOnInit() {
  }

  Back() : void {
    this._location.back();
  }

}
