import { Component, OnInit } from '@angular/core';
import { ArticleStorageService } from 'src/app/services/Api/article-storage.service';
import { ActivatedRoute } from '@angular/router';
import { RoleService } from 'src/app/services/Role.service';
import { ToastService } from 'src/app/services/Toast.service';
import { Article } from 'src/app/models/Article';
import { CommentStorageService } from 'src/app/services/Api/comment-storage.service';
import { CommentCollection } from 'src/app/models/collections';


@Component({
  selector: 'app-article-full-view',
  templateUrl: './article-full-view.component.html',
  styleUrls: ['./article-full-view.component.css'],
  providers: [
    ArticleStorageService, 
    RoleService, 
    ToastService, 
    CommentStorageService
  ]
})
export class ArticleFullViewComponent implements OnInit {
  article: Article;
  comments: CommentCollection;
  show: boolean = false;
  showCommentSection: boolean = false;

  constructor(
    private _articleStrorage: ArticleStorageService,
    private _route: ActivatedRoute,
    private _role: RoleService,
    private _toast: ToastService,
    private _commentStorage: CommentStorageService
  ) { }

  ngOnInit() {    
    const slug = this._route.snapshot.paramMap.get('slug');
    this.getArticle(slug);
  }

  getArticle(slug: string) : void {
    this._articleStrorage.GetBySlug(slug)
      .subscribe((result: Article) => {
        this.article = result;
        console.log(this.article);
        this.show = true;
      }, (error) => { 
        this._toast.dropDanger("A cikk lekérése ismeretlen okból meghíusult.");
        console.error(error); 
      });
  }

  IsAdmin() : boolean {
    return this._role.IsAdmin();
  }

  LoadComments(articleId: number) : void {    
    this._commentStorage.GetAllCommentForArticle(articleId)
      .subscribe((response: CommentCollection) => {
        this.comments = response;
        this.showCommentSection = true;
      }, (error: any) => {
        this._toast.dropDanger("Kommentek betöltése sikertelen.");
        console.error(error);
      });
  }
}
