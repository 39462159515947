import { Component, OnInit } from '@angular/core';
import { RoleService } from 'src/app/services/Role.service';
import { RedirectService } from 'src/app/services/Redirect.service';
import { AuthService } from 'src/app/services/Api/Auth.service';

enum Menu {
  Main, User, ShopAdmin
}

@Component({
  selector: 'app-Sidebar',
  templateUrl: './Sidebar.component.html',
  styleUrls: ['./Sidebar.component.css'],
  providers: [RoleService, AuthService, RedirectService]
})
export class SidebarComponent implements OnInit {
  username: string;
  
  menu: any = Menu;
  activeMenu: Menu = Menu.Main;

  constructor(
    private _role: RoleService,
    private _auth: AuthService,
  ) { }

  menuChange(menuType: number): void {
    this.activeMenu = menuType;
  }

  IsAdmin() : boolean {
    return this._role.IsAdmin();
  }
  ngOnInit() {
    this.username = this._role.Username();
  }

  logout(): void {
    this._auth.Logout();
  }

}
