import { Injectable } from "@angular/core";
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LandingpageModifyComponent } from '../UserArea/_InnerPages/landingpage-modify/landingpage-modify.component';

@Injectable()
export class LandingpagePreventUnsavedChanges implements CanDeactivate<LandingpageModifyComponent> {
  canDeactivate(component: LandingpageModifyComponent): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(component.Form.dirty) {
      return confirm('Még nem mentetted el az adatokat. El szeretnéd hagyni anélkül, hogy mentetted volna?');
    }
    return true;
  }

}