import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import * as M from 'materialize-css';
import { User } from 'src/app/models/User';
import { TransferRequest } from 'src/app/models/Transactions/TransferRequest';
import { TransactionService } from 'src/app/services/Api/transaction.service';
import { ToastService } from 'src/app/services/Toast.service';

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.css'],
  providers: [TransactionService, ToastService]
})
export class TransferModalComponent implements OnInit, AfterViewInit {
  modal: M.Modal;
  transfer: TransferRequest = new TransferRequest();

  @Output() amountChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() currentAmount: number;

  constructor(private _transactionService: TransactionService, private _toast: ToastService) { }

  private modalInit(): void {
    const modalElement = document.getElementById('TransferModal');
    this.modal = M.Modal.init(modalElement, {
      onOpenEnd: this.modalOpenCallback,
      onCloseStart: this.afterCloseModal,
      dismissible: false
    });
  }

  private tooltipInit(): void {
    const tooltipElements = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltipElements);
  }

  private modalOpenCallback = () => {
    //console.log('usedAmount:' + this.usedAmount);
  }

  ngAfterViewInit(): void {
    this.modalInit();
    this.tooltipInit();
  }

  addAllTCrButtonDisabled: boolean = false;
  usedAmount: number;

  ReCalculate(): void {
    var r: string = Number.parseFloat(this.transfer.Amount.toString()).toFixed(2);
    this.transfer.Amount = Number.parseFloat(r);

    if(this.transfer.Amount > this.currentAmount)
      this.transfer.Amount = this.currentAmount;

    if(this.transfer.Amount < 0)
      this.transfer.Amount = 0;

    this.usedAmount = this.currentAmount - this.transfer.Amount;  
  }

  addAllTCr(): void {    
    if(this.currentAmount !== 0) {
      this.transfer.Amount += this.usedAmount;
      this.usedAmount -= this.transfer.Amount;
      this.addAllTCrButtonDisabled = true;
    }
  }

  ngOnInit() {
    this.usedAmount = this.currentAmount;
  }

  selectedUser(user: User): void {
    this.transfer.ToId = user.id;
  }

  afterCloseModal = (): void => {
    this.transfer = new TransferRequest();
    this.usedAmount = this.currentAmount;
  }

  transferSubmit(): void {
    this._transactionService.submitTransfer(this.transfer)
      .subscribe((response: any) => {
        this._toast.dropSuccess("Sikeresen átküldve!");
        this.amountChange.emit(this.transfer.Amount);
        this.modal.close();
      } ,(error) => this._toast.dropDanger("Sikertelen Tudáskredit átküldés."))
  }
}
