import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { API_ADDRESS } from 'src/app/app.component';
import { WalletCollection } from 'src/app/models/collections';
import { User } from 'src/app/models/User';
import { map } from 'rxjs/operators';
import { Wallet } from 'src/app/models/Wallet';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private _http: HttpClient) { }

  CreateWallets() : Observable<any> {
    return this._http.post<any>(API_ADDRESS + "/api/wallets/create", true);
  }

  GetByUser(user: User) : Observable<WalletCollection> {
    return this._http.get<WalletCollection>(API_ADDRESS+"/api/wallets/getByUser")
      .pipe(
        map((response: WalletCollection) => {
          let result: WalletCollection = [];
          for(let wallet of response) {
            result.push(new Wallet(wallet));
          }
          return result;
        }, error => console.error(error))
      )
  }
}
