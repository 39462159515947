import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/Toast.service';
import { UserStorageService } from 'src/app/services/Api/user-storage.service';
import { IUser } from 'src/app/models/IUser';

@Component({
  selector: 'app-Users',
  templateUrl: './Users.component.html',
  styleUrls: ['./Users.component.css']
})
export class UsersComponent implements OnInit {
  userList: IUser[];

  constructor(
    private _userStorage: UserStorageService,
    private _toast: ToastService
  ) { }

  ngOnInit() {
    this.getUserList();
  }

  getUserList() {
    this._userStorage.GetUsers()
      .subscribe((result: IUser[]) => {
        console.log(result);
        this.userList = result;
      }, (error) => { 
        this._toast.dropDanger("Felhasználók listáját nem sikerült lekérdezni");
        console.error(error) 
      });
  }

}
