import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ISettings } from '../models/ISettings';
import { Observable, of } from 'rxjs';
import { SettingsService } from '../services/Api/settings.service';
import { ToastService } from '../services/Toast.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SettingsResolver implements Resolve<ISettings> {

  constructor(
    private _settingsService: SettingsService,
    private _toast: ToastService
    ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ISettings> {
    return this._settingsService.Current().pipe(
      catchError(error => {
        this._toast.dropDanger("Problem retrieving settings data");
        return of(null);
      })
    )
  }
  
}
