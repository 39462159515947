import { Injectable, HostListener } from "@angular/core";
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SettingsComponent } from '../UserArea/_InnerPages/settings/settings.component';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsPreventUnsavedChanges implements CanDeactivate<SettingsComponent> {
  canDeactivate(component: SettingsComponent): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(component.Form.dirty) {
      return confirm('Még nem mentetted el az adatokat. El szeretnéd hagyni anélkül, hogy mentetted volna?');
    }
    return true;
  }

}