import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { API_ADDRESS } from 'src/app/app.component';
import { AuthorizeService } from './Authorize.service';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { UserCollection } from 'src/app/models/collections';
import { IUserFilter } from 'src/app/models/Filters/IUserFilter';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {

  constructor(
    private _http: HttpClient,
    private _authorize: AuthorizeService,
    private _httpHelper: HttpHelperService
  ) { }
  
    GetUsers(filter: IUserFilter = null) : Observable<UserCollection> {
      return this._http.get<UserCollection>(API_ADDRESS + "/api/users?" + this.toQueryString(filter), 
        { headers: this._authorize.getAuthHeader() });
    }

    toQueryString(filter: IUserFilter = null) : string {
      return this._httpHelper.ToUrlQueryString(filter);
    }
  
    GetById(id: string) : Observable<User> {
      return this._http.get<User>(API_ADDRESS + "/api/Users/" + id,
        { headers: this._authorize.getAuthHeader() })
        .pipe(map((result: any) => {
          return new User(result);
        }, (error) => { console.error(error); }));
    }
}
