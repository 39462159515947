import { Component, OnInit } from "@angular/core";
import { TwoFactorService } from "../../../../services/Api/two-factor.service";
import { ITwoFactorEnabled } from "../../../../models/TwoFactor/ITwoFactorEnabled";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { IFormInputValidationState } from "src/app/models/IFormInputValidationState";
import { AuthService } from "../../../../services/Api/Auth.service";
import { RedirectService } from "../../../../services/Redirect.service";

@Component({
  selector: "app-enable-authenticator",
  templateUrl: "./enable-authenticator.component.html",
  styleUrls: ["./enable-authenticator.component.css"],
  providers: [TwoFactorService, AuthService, RedirectService],
})
export class EnableAuthenticatorComponent implements OnInit {
  twoFactorData: ITwoFactorEnabled = {} as ITwoFactorEnabled;
  getDataResponse: boolean = false;
  twoFactorVerificationCodeForm: FormGroup;

  validationState: IFormInputValidationState = {} as IFormInputValidationState;

  constructor(
    private _twoFactorService: TwoFactorService,
    private _authService: AuthService,
    private _redirectService: RedirectService
  ) {}

  ngOnInit() {
    this.getData();
    this.twoFactorVerificationCodeFormHandler();
  }

  getData(): void {
    this._twoFactorService.GetTwoFactorEnabledData().subscribe((response) => {
      this.getDataResponse = true;
      this.twoFactorData = response;
    });
  }

  submitVerificationCode(): void {
    this._twoFactorService
      .VerifycationCode(this.twoFactorVerificationCodeForm.value)
      .subscribe(
        (response) => {
          console.log(response);
          this.validationState.message = response.message;
          this.validationState.isValid = true;
          this.validationState.class = "";
          this._authService.RefreshCurrentUserData().subscribe(
            (response) => {
              this._redirectService.ToTwoFactorAuthentication();
            },
            (error) => {
              console.error(error);
            }
          );
        },
        (error) => {
          console.error(error);
          this.validationState.class = "invalid";
          this.validationState.message = error.error.message;
          this.validationState.isValid = false;
        }
      );
  }

  twoFactorVerificationCodeFormHandler() {
    this.twoFactorVerificationCodeForm = new FormGroup({
      code: new FormControl("", [Validators.required]),
    });
  }
}
