import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {

  constructor() { }

  ToUrlQueryString(obj: object = null): string {
    if(obj === null) return "";
    
    var parts = [];
      for(var property in obj) {
        var value = obj[property];
        if(value !== null && value !== undefined) 
          parts.push(encodeURIComponent(property)+'='+encodeURIComponent(value));          
      }

      return parts.join('&');
  }
}
