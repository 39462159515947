import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ToastService } from 'src/app/services/Toast.service';
import { SettingsService } from 'src/app/services/Api/settings.service';
import { ExchangeService } from 'src/app/services/Api/exchange.service';
import { IExchangeResponse } from 'src/app/models/Exchanges/IExchangeResponse';
import { ISettings } from 'src/app/models/ISettings';
import { MaterializeInitService } from 'src/app/services/materialize-init.service';
import { BuyTCrMoneyTransferRequest } from 'src/app/models/Transactions/BuyTCrMoneyTransferRequest';
import { MClasses } from 'src/app/enums/MClasses.enum';
import * as M from 'materialize-css';
import { TransactionService } from 'src/app/services/Api/transaction.service';

@Component({
  selector: 'app-buy-tcr-modal',
  templateUrl: './buy-tcr-modal.component.html',
  styleUrls: ['./buy-tcr-modal.component.css'],
  providers: [
    ToastService, 
    SettingsService, 
    ExchangeService, 
    MaterializeInitService,
    TransactionService
  ]
})
export class BuyTCrModalComponent implements OnInit, AfterViewInit {
  exchange: IExchangeResponse = {} as IExchangeResponse;
  settings: ISettings = {} as ISettings;
  request: BuyTCrMoneyTransferRequest = new BuyTCrMoneyTransferRequest();
  refreshBTCExchange: boolean = false;

  modal: M.Modal;

  @ViewChild("noteField", {static: true}) noteField: ElementRef<HTMLTextAreaElement>;
  @ViewChild("Amount", {static: true}) Amount: ElementRef<HTMLInputElement>;

  constructor(
    private _toast: ToastService,
    private _settings: SettingsService,
    private _exchange: ExchangeService,
    private _materlialize: MaterializeInitService,
    private _transactions: TransactionService
  ) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this._materlialize.CharacterCounter(this.noteField.nativeElement);
    this.modalInit();
  }

  private modalInit(): void {
    const modalElement = document.getElementById('BuyTCrTransferModal');
    this.modal = M.Modal.init(modalElement, {
      onOpenEnd: this.modalOpenCallback,
      dismissible: false
    });
  }

  private modalOpenCallback = () => {
    this.getSettings();
  }

  ReCalculateExcange(): void {
    console.log(this.request.Amount);
    if(this.request.Amount >= this.settings.tCrMinimalBuyingAmmount) {
      this.Exchange(this.request.Amount);

    }
  }

  private getSettings(): void {
    this._settings.Current()
      .subscribe(response => {
        this.settings = response;
        this.request.Amount = this.settings.tCrMinimalBuyingAmmount;
        this.Amount.nativeElement.nextElementSibling.classList.add(MClasses.active);
        this.Exchange(this.request.Amount);
        this.ExchangeLoop();
      }, error => { 
        console.error(error);
        this._toast.dropDanger("Setting data not available!");
      });
  }

  private Exchange(amount: number): void {
    this.refreshBTCExchange = true;
    this._exchange.ExchangeTCr(amount)
      .subscribe(response => {
        this.exchange = response;
        this.refreshBTCExchange = false;
      }, error => { 
        console.error(error);
        this._toast.dropDanger("Exhange service run on error!");
      });
  }

  private ExchangeLoop(): void {
    setInterval(() => {      
      this.Exchange(this.request.Amount);
    }, 60000);    
  }

  afterSubmit(): void {
    this.modal.close();
    this.request = new BuyTCrMoneyTransferRequest();
  }

  submit(): void {
    console.log(this.request);
    this._transactions.submitBuyTCrTransfer(this.request)
      .subscribe(response => {
        this._toast.dropSuccess("Tranzakció sikeresen rögzítve.");
        this.afterSubmit();
      }, error => {
        console.error(error);
        this._toast.dropDanger("Sikertelen tranzakció rögzítési kísérlet.");
      });
  }
}
