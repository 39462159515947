import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductCollection } from 'src/app/models/collections';
import { BookService } from './book.service';
import { EbookService } from './ebook.service';
import { forkJoin } from 'rxjs'
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private _http: HttpClient,
    private _bookService: BookService,
    private _ebookService: EbookService
    ) { }

  GetProducts(): Observable<ProductCollection> {
    return forkJoin(
      this._bookService.GetBooks(),
      this._ebookService.GetEbooks()
    )
    .pipe(
      map(([books, ebooks]) => {
        let resultCollection: ProductCollection = [];
          for(let book of books) {
            resultCollection.push(book);
          }
          for(let ebook of ebooks) {
            resultCollection.push(ebook);
          }
        return resultCollection;
      })
    )
  }
}
