import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductCollection } from 'src/app/models/collections';

@Component({
  selector: 'app-publications-shop',
  templateUrl: './publications-shop.component.html',
  styleUrls: ['./publications-shop.component.css']
})
export class PublicationsShopComponent implements OnInit {
  products: ProductCollection;

  constructor(
    private _route: ActivatedRoute

  ) { }

  ngOnInit() {
    this._route.data.subscribe(data => {
      this.products = data['products'];
      console.log(this.products);
    });
  }

}
