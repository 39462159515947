import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EbookCollection } from 'src/app/models/collections';
import { API_ADDRESS } from 'src/app/app.component';
import { map } from 'rxjs/operators';
import { Ebook } from 'src/app/models/Products/Ebook';

@Injectable({
  providedIn: 'root'
})
export class EbookService {

  constructor(private _http: HttpClient) { }

  GetEbooks(): Observable<EbookCollection> {
    return this._http.get<EbookCollection>(API_ADDRESS + "/api/Ebooks")
      .pipe(
        map((response: EbookCollection) => {
          let resp: EbookCollection = [];
            for(let ebook of response) {
              resp.push(new Ebook(ebook));
            }

          return resp;
        })
      )
  }
}
