import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publications-shop-search-bar',
  templateUrl: './publications-shop-search-bar.component.html',
  styleUrls: ['./publications-shop-search-bar.component.css']
})
export class PublicationsShopSearchBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
