import { CurrencyTypes } from '../enums/CurrencyTypes.enum';

export class Wallet {
    balance: number;
    currencyType: CurrencyTypes;

    constructor(wallet: Wallet = null) {
        if(wallet !== null) {
            Object.assign(this, wallet);
        }
    }
}
