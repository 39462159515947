import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ILandingPage } from '../models/ILandingPage';
import { ActivatedRoute } from '@angular/router';
import { MaterializeInitService } from '../services/materialize-init.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
  providers: [MaterializeInitService]
})
export class LandingpageComponent implements OnInit, AfterViewInit {

  landingpage: ILandingPage = {} as ILandingPage;

  show: boolean = false;
  constructor(
    private _route: ActivatedRoute,
    private _materialize: MaterializeInitService
    ) { }

  ngOnInit() {
    this.getLandingpage();   
  }

  ngAfterViewInit(): void {
    this._materialize.ScrollSpy();
    this._materialize.Sidenav();
    this._materialize.Dropdown();
    this._materialize.Modal();
  };

  private getLandingpage() {
    this._route.data.subscribe(data => {
      this.landingpage = data['landing'];
      this.show = true;
    })
  }
}
