import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ArticleStorageService } from 'src/app/services/Api/article-storage.service';
import { RoleService } from 'src/app/services/Role.service';
import { ArticleCollection } from 'src/app/models/collections';
import { ToastService } from 'src/app/services/Toast.service';
import { MaterializeInitService } from 'src/app/services/materialize-init.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css'],
  providers: [ArticleStorageService, RoleService, ToastService, MaterializeInitService]
})
export class ArticlesComponent implements OnInit {

  @ViewChild("panel", { static: true }) panel: ElementRef<HTMLUListElement>;

  publishedArticles: ArticleCollection;

  unPublishedArticles: ArticleCollection;

  cardShow: boolean = false;

  constructor(
    private _articleStorage: ArticleStorageService,
    public role: RoleService,
    private _toast: ToastService,
    private _materialize: MaterializeInitService
  ) { }

  change() : void {
    if(!this.cardShow) 
    {
      this.cardShow = !this.cardShow;
      this.panel.nativeElement.classList.remove('scale-in');
      this.panel.nativeElement.classList.add('scale-out');
    }
    else
    {
      this.cardShow = !this.cardShow;
      this.panel.nativeElement.classList.remove('scale-out');
      this.panel.nativeElement.classList.add('scale-in');
    }
  }

  ngOnInit() {
    this.getPublished();
    this.getUnPublished();
  }

  getPublished() : void {
    this._articleStorage.FetchAllPublished()
      .subscribe((response: ArticleCollection) => {
        console.log(response);
        this.publishedArticles = response;
        this._materialize.Collapsibile();
      }, (error) => { 
        this._toast.dropDanger("Cikkek kiolvasása, ismeretlen okból, sikertelen.");
        console.error(error);
       });
  }

  getUnPublished() : void {
    this._articleStorage.FetchAllUnPublished()
      .subscribe((response: ArticleCollection) => {
        console.log(response);
        this.unPublishedArticles = response;
        this._materialize.Collapsibile();
      }, (error) => { 
        this._toast.dropDanger("Cikkek kiolvasása, ismeretlen okból, sikertelen.");
        console.error(error);
       });
  }
}
