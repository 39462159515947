import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IExchangeResponse } from 'src/app/models/Exchanges/IExchangeResponse';
import { API_ADDRESS } from 'src/app/app.component';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {

  constructor(private _http: HttpClient) { }

  ExchangeTCr(amount: number): Observable<IExchangeResponse> {
    return this._http.post<IExchangeResponse>(
      API_ADDRESS + "/api/Transactions/exchangeToBitCoin", { From: "EUR", Amount: amount}
      )
  }
}
