import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPreRegistratedUser } from 'src/app/models/IPreRegistratedUser';
import { API_ADDRESS } from 'src/app/app.component';
import { map } from 'rxjs/operators';
import { AuthorizeService } from './Authorize.service';

@Injectable({
  providedIn: 'root'
})
export class PreRegistratedUserService {

  constructor(private _http: HttpClient, private _authorize: AuthorizeService) { }
  
  getAll() : Observable<IPreRegistratedUser[]> {
    return this._http.get<IPreRegistratedUser[]>(API_ADDRESS + "/api/PreRegistrated/list",
        { headers: this._authorize.getAuthHeader() }).pipe(map((response: IPreRegistratedUser[]) => {
          let result: IPreRegistratedUser[] = [];
          for(let user of response) {
            user.creationTime = new Date(user.creationTime);
            if(user.modifiedDate !== null) {
              user.modifiedDate = new Date(user.modifiedDate);
            }
            if(user.registrationKey !== null) {
              user.registrationKey.creationTime = new Date(user.registrationKey.creationTime);
              let expired: Date = new Date(user.registrationKey.creationTime);
              expired.setDate(expired.getDate() + 1);
              user.registrationKey.expired = expired;
            }
            result.push(user);
          }

          return result;
        }));
  }

  
  
}
