import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ITwoFactorEnabled } from "../../models/TwoFactor/ITwoFactorEnabled";
import { IAuthenticatorCode } from "src/app/models/TwoFactor/IAuthenticatorCode";
import { API_ADDRESS } from "src/app/app.component";

@Injectable({
  providedIn: "root",
})
export class TwoFactorService {
  constructor(private _http: HttpClient) {}

  GetTwoFactorEnabledData(): Observable<ITwoFactorEnabled> {
    return this._http.get<ITwoFactorEnabled>(
      API_ADDRESS + "/api/TwoFactor/SharedKeyAndQrcode"
    );
  }

  VerifycationCode(model: IAuthenticatorCode): Observable<any> {
    return this._http.post(
      API_ADDRESS + "/api/TwoFactor/VerificationCode",
      model
    );
  }

  ResetAuthenticatorKey(): Observable<any> {
    return this._http.post(
      API_ADDRESS + "/api/TwoFactor/ResetAuthenticatorKey",
      {}
    );
  }
}
