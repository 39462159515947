import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ToastService } from '../services/Toast.service';
import { catchError } from 'rxjs/operators';
import { WalletService } from '../services/Api/wallet.service';
import { AuthService } from '../services/Api/Auth.service';
import { WalletCollection } from '../models/collections';

@Injectable()
export class UserWalletsResolver implements Resolve<WalletCollection> {

  constructor(
    private _toast: ToastService,
    private _walletService: WalletService,
    private _auth: AuthService
    ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WalletCollection> {
    const currentUser = this._auth.CurrentUser();
    if(currentUser !== null && currentUser.enableWallets) {
      return this._walletService.GetByUser(this._auth.CurrentUser()).pipe(
        catchError(error => {
          this._toast.dropDanger("Problem retrieving wallets data, Username: "+currentUser.userName);
          return of(null);
        })
      )   
    } 
  }  
}
