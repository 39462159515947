import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-me',
  templateUrl: './profile-me.component.html',
  styleUrls: ['./profile-me.component.css']
})
export class ProfileMeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
