import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/Api/Auth.service";
import { ToastService } from "src/app/services/Toast.service";
import { RedirectService } from "src/app/services/Redirect.service";
import { ILoginRequest } from "src/app/models/ILoginRequest";
import { HttpErrorResponse } from "@angular/common/http";
import { ILoginRequestWithTwoFa } from "../../models/ILoginRequestWithTwoFa";

@Component({
  selector: "app-Login",
  templateUrl: "./Login.component.html",
  styleUrls: ["../landingpage.component.css", "./Login.component.css"],
})
export class LoginComponent implements OnInit {
  request: ILoginRequest = {} as ILoginRequest;

  request2FA: ILoginRequestWithTwoFa = {} as ILoginRequestWithTwoFa;
  enable2FA: boolean = false;

  constructor(
    private _auth: AuthService,
    private _toast: ToastService,
    private _redirect: RedirectService
  ) {}

  login(event: any): void {
    this._auth.login(this.request).subscribe(
      (result: any) => {
        this._toast.dropSuccess("Sikeres belépés!");
        this.loggedIn();
      },
      (error: HttpErrorResponse) => {
        if (error.status === 444) {
          this.enable2FA = true;
          this._toast.dropPrimary("Kérem adja meg az Authenticator kódját!");
        } else {
          this._toast.dropDanger("Belépés megtagadva!");
          console.error(error);
        }
      }
    );
  }

  login2FA(event: any): void {
    this.request2FA.Username = this.request.Username;
    this.request2FA.Password = this.request.Password;
    this._auth.loginWith2FA(this.request2FA).subscribe(
      (result: any) => {
        this._toast.dropSuccess("Sikeres belépés!");
        this.loggedIn();
      },
      (error: HttpErrorResponse) => {
        this._toast.dropDanger("Belépés megtagadva!");
        console.error(error);
      }
    );
  }

  loggedIn(): void {
    if (this._auth.loggedIn()) {
      this._redirect.ToUserArea();
    }
  }

  ngOnInit() {
    this.loggedIn();
  }
}
