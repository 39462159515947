import { User } from './User';
import { IComment } from './IComment';

export class Comment {
    author: User;
    body: string;
    deleted: boolean;
    creationTime: Date;
    modifiedDate: Date;

    constructor(comment: IComment = null) {
        if(comment !== null) {
            Object.assign(this, comment);

            if(comment.author !== null)
                this.author = new User(comment.author);

            if(comment.creationTime !== null) 
                this.creationTime = new Date(comment.creationTime);
                
            if(comment.modifiedDate !== null)
                this.modifiedDate = new Date(comment.modifiedDate);    
        }
    }
}
