import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LandingpageService } from 'src/app/services/Api/Landingpage.service';
import { ILandingPage } from 'src/app/models/ILandingPage';
import { ToastService } from 'src/app/services/Toast.service';
import * as M from 'materialize-css';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-landingpage-modify',
  templateUrl: './landingpage-modify.component.html',
  styleUrls: ['./landingpage-modify.component.css']
})
export class LandingpageModifyComponent implements OnInit {

  landingPage: ILandingPage = {} as ILandingPage;

  @ViewChild("textareaDescription", {static: true}) textareaDescription: ElementRef<HTMLTextAreaElement>;
  @ViewChild("inputYoutubeVideo", {static: true}) inputYoutubeVideo: ElementRef<HTMLInputElement>;
  @ViewChild('landingPageModifyForm', {static: true}) Form: NgForm;
  
  constructor(
    private _landingPageService: LandingpageService,
    private _toast: ToastService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getActualLandingPage();
  }

  getActualLandingPage() : void {
    this._route.data.subscribe(data => {
      this.landingPage = data['landing'];
      this.handlingTextFields(this.landingPage);
    });
  }

  private handlingTextFields(landingPage: ILandingPage) : void {
    this.textareaDescription.nativeElement.value = landingPage.description;
    M.textareaAutoResize(this.textareaDescription.nativeElement);
    M.updateTextFields();
    this.inputYoutubeVideo.nativeElement.nextElementSibling.classList.add("active");
  }

  save() : void {
    this._landingPageService.update(this.landingPage)
      .subscribe((result: any) => {
        this._toast.dropSuccess("Landingpage sikeresen mentve!");
      }, (error) => { 
        console.log(error);
        this._toast.dropDanger("Sikertelen mentés!");
      });
  }
}
