import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ArticleStorageService } from 'src/app/services/Api/article-storage.service';
import { IArticle } from 'src/app/models/IArticle';
import { ToastService } from 'src/app/services/Toast.service';
import { Article } from 'src/app/models/Article';
import { IChip } from 'src/app/models/IChip';
import { Tag } from 'src/app/models/Tag';
import { RedirectService } from 'src/app/services/Redirect.service';
import { TagStorageService } from 'src/app/services/Api/tag-storage.service';
import { TagCollection } from 'src/app/models/collections';
import * as M from 'materialize-css';

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.css'],
  providers: [ToastService, ArticleStorageService, RedirectService, TagStorageService]
})
export class AddArticleComponent implements OnInit {
  private _tagField: M.Chips;
  article: Article = new Article();

  @ViewChild('tagInputField', { static: true }) tagInputField: ElementRef;

  constructor(
    private _articleStorage: ArticleStorageService,
    private _toast: ToastService,
    private _redirect: RedirectService,
    private _tagStorage: TagStorageService
  ) { }

  ngOnInit() {
    this._initTagInputField();
  }

  private _initTagInputField() : void {
    this._tagStorage.GetAll()
      .subscribe((response: TagCollection) => {
        var elems = document.querySelectorAll('.chips');

        let data: any = {};
        for(let tag of response as TagCollection) {
          data[tag.name] = null;
        }

        M.Chips.init(elems, {
          autocompleteOptions: {
            data: data,
            limit: Infinity,
            minLength: 1
          },
          placeholder: 'Adj meg címkéket! ...',
          secondaryPlaceholder: '+Címke'
        });
        this._tagField = M.Chips.getInstance(this.tagInputField.nativeElement);
      }, (error) => {
        this._toast.dropDanger("Címkék lekérdezése nem sikerült, autocomplete nem működik.");
        console.error(error); 
      });  
  }

  private _addTags(article: Article) : void {
    for(let chip of this._tagField.chipsData as IChip[]) {
      this.article.tags.push(new Tag({ name: chip.tag }));
    }
  }

  save() : void {
    this._addTags(this.article);

    this._articleStorage.Save(this.article)
      .subscribe((response: any) => {
        this.article = new Article();
        this._tagField.chipsData = [];
        this._toast.dropSuccess(this.article.title + " cikk sikeresen mentve!");
        this._redirect.ToArticles();
      }, (error) => { 
        this.article.tags = [];
        this._toast.dropDanger("Cikk mentése ismeretlen okból meghíusult!");
      });
  }

  
}
