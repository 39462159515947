import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClipboardModule } from "ngx-clipboard";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent, API_ADDRESS } from "./app.component";
import { LandingpageComponent } from "./landingpage/landingpage.component";
import { RegistrationPageComponent } from "./RegistrationPage/RegistrationPage.component";
import { ButtonCircleComponent } from "./Elements/ButtonCircle/ButtonCircle.component";
import { LinearComponent } from "./Elements/Spinners/Linear/Linear.component";
import { YoutubeUrlPipe } from "./Pipes/YoutubeUrl.pipe";
import { CircularComponent } from "./Elements/Spinners/Circular/Circular.component";
import { NotFoundComponent } from "./NotFound/NotFound.component";
import { LoginComponent } from "./landingpage/Login/Login.component";
import { UserAreaComponent } from "./UserArea/UserArea.component";
import { PreRegistratedUsersComponent } from "./UserArea/_InnerPages/PreRegistratedUsers/PreRegistratedUsers.component";
import { SidebarComponent } from "./UserArea/Sidebar/Sidebar.component";
import { TopbarComponent } from "./UserArea/Topbar/Topbar.component";
import { UsersComponent } from "./UserArea/_InnerPages/Users/Users.component";
import { LandingpageModifyComponent } from "./UserArea/_InnerPages/landingpage-modify/landingpage-modify.component";
import { UserViewPanelComponent } from "./UserArea/Sidebar/user-view-panel/user-view-panel.component";
import { AddArticleComponent } from "./UserArea/_InnerPages/Article/add-article/add-article.component";
import { ArticlesComponent } from "./UserArea/_InnerPages/Article/articles/articles.component";
import { ArticlePreviewListComponent } from "./_components/article-preview-list/article-preview-list.component";
import { ArticleFullViewComponent } from "./UserArea/_InnerPages/Article/article-full-view/article-full-view.component";
import { TelegramIDComponent } from "./landingpage/telegram-id/telegram-id.component";
import { ButtonBackToPreviousPageComponent } from "./Elements/button-back-to-previous-page/button-back-to-previous-page.component";
import { TagBoxViewComponent } from "./_components/tag-box-view/tag-box-view.component";
import { TagContentsComponent } from "./UserArea/_InnerPages/Tag/tag-contents/tag-contents.component";
import { ButtonComponent } from "./Elements/button/button.component";
import { EditArticleComponent } from "./UserArea/_InnerPages/Article/edit-article/edit-article.component";
import { CommentSectionComponent } from "./_components/comment-section/comment-section.component";
import { WalletComponent } from "./UserArea/_InnerPages/Wallet/wallet/wallet.component";
import { LocalStorageFields } from "./enums/LocalStorageFields.enum";
import { JwtModule } from "@auth0/angular-jwt";
import { SettingsComponent } from "./UserArea/_InnerPages/settings/settings.component";
import { ToastService } from "./services/Toast.service";
import { SettingsResolver } from "./_resolvers/settings.resolver";
import { SettingsPreventUnsavedChanges } from "./_guards/settings-prevent-unsaved-changes.guard";
import { UserWalletsResolver } from "./_resolvers/user-wallets.resolver";
import { LandingpageResolver } from "./_resolvers/landingpage.resolver";
import { LandingpagePreventUnsavedChanges } from "./_guards/landingpage-prevent-unsaved-changes.guard copy";
import { BuyTCrModalComponent } from "./UserArea/_InnerPages/Wallet/buy-tcr-modal/buy-tcr-modal.component";
import { TransferModalComponent } from "./UserArea/_InnerPages/Wallet/transfer-modal/transfer-modal.component";
import { PublicationsShopComponent } from "./UserArea/_InnerPages/Publications/publications-shop/publications-shop.component";
import { PublicationsShopSearchBarComponent } from "./UserArea/_InnerPages/Publications/publications-shop/publications-shop-search-bar/publications-shop-search-bar.component";
import { ProductGridComponent } from "./UserArea/_InnerPages/Publications/publications-shop/product-grid/product-grid.component";
import { ProductsResolver } from "./_resolvers/products.resolver";
import { BookGridItemComponent } from "./UserArea/_InnerPages/Publications/publications-shop/product-grid/book-grid-item/book-grid-item.component";
import { UserAutoSelectComponent } from "./_components/user-auto-select/user-auto-select.component";
import { TransactionsComponent } from "./UserArea/_InnerPages/Wallet/transactions/transactions.component";
import { ToolsComponent } from "./UserArea/_InnerPages/tools/tools.component";
import { TrainingsComponent } from "./UserArea/_InnerPages/trainings/trainings.component";
import { AddBookComponent } from "./UserArea/_InnerPages/add-book/add-book.component";
import { AddEbookComponent } from "./UserArea/_InnerPages/add-ebook/add-ebook.component";
import { OrdersComponent } from "./UserArea/_InnerPages/orders/orders.component";
import { LibraryAdminComponent } from "./UserArea/_InnerPages/library-admin/library-admin.component";
import { ProfileMeComponent } from "./UserArea/_InnerPages/profile-me/profile-me.component";
import { TwoFactorAuthenticationComponent } from "./UserArea/_InnerPages/two-factor-authentication/two-factor-authentication.component";
import { EnableAuthenticatorComponent } from "./UserArea/_InnerPages/two-factor-authentication/enable-authenticator/enable-authenticator.component";
import { ResetAuthenticatorComponent } from "./UserArea/_InnerPages/two-factor-authentication/reset-authenticator/reset-authenticator.component";
import { QRCodeModule } from "angularx-qrcode";
import { AlertComponent } from './_components/alert/alert.component';

export function tokenGetter() {
  return localStorage.getItem(LocalStorageFields.auth_token);
}

@NgModule({
  declarations: [
    AppComponent,
    LandingpageComponent,
    RegistrationPageComponent,
    ButtonCircleComponent,
    LinearComponent,
    YoutubeUrlPipe,
    CircularComponent,
    NotFoundComponent,
    LoginComponent,
    UserAreaComponent,
    PreRegistratedUsersComponent,
    SidebarComponent,
    TopbarComponent,
    UsersComponent,
    LandingpageModifyComponent,
    UserViewPanelComponent,
    AddArticleComponent,
    ArticlesComponent,
    ArticlePreviewListComponent,
    ArticleFullViewComponent,
    TelegramIDComponent,
    ButtonBackToPreviousPageComponent,
    TagBoxViewComponent,
    TagContentsComponent,
    ButtonComponent,
    EditArticleComponent,
    CommentSectionComponent,
    WalletComponent,
    SettingsComponent,
    BuyTCrModalComponent,
    TransferModalComponent,
    PublicationsShopComponent,
    PublicationsShopSearchBarComponent,
    ProductGridComponent,
    BookGridItemComponent,
    UserAutoSelectComponent,
    TransactionsComponent,
    ToolsComponent,
    TrainingsComponent,
    AddBookComponent,
    AddEbookComponent,
    OrdersComponent,
    LibraryAdminComponent,
    ProfileMeComponent,
    TwoFactorAuthenticationComponent,
    EnableAuthenticatorComponent,
    ResetAuthenticatorComponent,
    AlertComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ClipboardModule,
    AppRoutingModule,
    QRCodeModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          "localhost:44338",
          "localhost:5000",
          "devenyi-api.azurewebsites.net",
        ],
        blacklistedRoutes: [],
      },
    }),
  ],
  providers: [
    ToastService,
    SettingsResolver,
    SettingsPreventUnsavedChanges,
    UserWalletsResolver,
    ProductsResolver,
    LandingpageResolver,
    LandingpagePreventUnsavedChanges,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
