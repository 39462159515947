import { Component, OnInit, Input } from '@angular/core';
import { ProductCollection } from 'src/app/models/collections';
import { Book } from 'src/app/models/Products/Book';
import { Ebook } from 'src/app/models/Products/Ebook';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.css']
})
export class ProductGridComponent implements OnInit {
  @Input() products: ProductCollection;

  constructor() { }

  ngOnInit() {
  }

  IsBook(item: any): boolean {
    if(item instanceof Book)
      return true;

    return false;
  }

  IsEbook(item: any): boolean {
    if(item instanceof Ebook)
      return true;

    return false;  
  }

}
