import { Component, OnInit } from "@angular/core";
import { Config } from "./enums/Config";

export const API_ADDRESS = Config.api_address_azure;
declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "DevenyiApp-Front";

  /** browse and page close event */
  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {
  //   $event.returnValue = true;
  // }

  constructor() {}

  ngOnInit(): void {}
}
