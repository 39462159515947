import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ToastService } from '../services/Toast.service';
import { catchError } from 'rxjs/operators';
import { ProductCollection } from '../models/collections';
import { ProductService } from '../services/Api/product.service';

@Injectable()
export class ProductsResolver implements Resolve<ProductCollection> {

  constructor(
    private _productService: ProductService,
    private _toast: ToastService
    ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductCollection> {
    return this._productService.GetProducts().pipe(
      catchError(error => {
        this._toast.dropDanger("Problem retrieving products data");
        return of(null);
      })
    )
  }
  
}
