import { IProduct } from './IProduct';

export class Ebook implements IProduct {
    title: string;    
    isShow: boolean;
    productPrice: number;
    productImage: string;
    productYoutubeVideo: string;
    productDescription: string;
    slug: string;
    creationTime: Date;
    isPrintable: boolean;
    pdf: string;
    author: string;

    constructor(ebook: Ebook = null) {
        if(ebook !== null) {
            Object.assign(this, ebook);

            this.creationTime = new Date(ebook.creationTime);
        }
    }
}
