import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IDecodedJwtToken } from 'src/app/models/IDecodedJwtToken';
import { Roles } from 'src/app/enums/Roles.enum';
import { LocalStorageFields } from 'src/app/enums/LocalStorageFields.enum';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private _jwtHelper = new JwtHelperService();

  constructor() { }

  private readTokenFromStorage() : IDecodedJwtToken {
    const token = localStorage.getItem(LocalStorageFields.auth_token);
    if(token === null) {
      return null;
    }
    return this._jwtHelper.decodeToken(token);
  }

  Username() : string {
    return this.readTokenFromStorage().unique_name;
  }

  IsAdmin() : boolean {
    return this.IsRole(Roles.Admin);
  }

  IsMember() : boolean {
    return this.IsRole(Roles.Member);
    
  }

  IsRole(roleName: string) : boolean {
    if(this.readTokenFromStorage().role === roleName) {
      return true;
    }

    return false;
  }
}
