import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { API_ADDRESS } from 'src/app/app.component';
import { map } from 'rxjs/operators';
import { AuthorizeService } from './Authorize.service';
import { ITag } from 'src/app/models/ITag';
import { ArticleCollection, TagCollection } from 'src/app/models/collections';
import { Tag } from 'src/app/models/Tag';
import { Article } from 'src/app/models/Article';

@Injectable({
  providedIn: 'root'
})
export class TagStorageService {

  constructor(
    private _http: HttpClient,
    private _authorize: AuthorizeService
  ) { }

  GetBySlug(slug: string) : Observable<ITag> {
    return this._http.get<ITag>(
        API_ADDRESS + "/api/Tags/" + slug,
        { headers: this._authorize.getAuthHeader() }
        )
      .pipe(map((response: any) => {
        return new Tag(response);
      }, (error) => { console.error(error); }));
  }
  
  GetTagContents(slug: string) : Observable<ArticleCollection> {
    return this._http.get(
        API_ADDRESS + "/api/Tags/" + slug + "/contents",
        { headers: this._authorize.getAuthHeader() }
        )
      .pipe(map((result: any) => {
        let res: ArticleCollection = [];
          for(let resource of result as ArticleCollection) {
            res.push(new Article(resource));
          }
        return res;
      }, (error) => { console.error(error); }));
  }

  GetAll() : Observable<TagCollection> {
    return this._http.get<TagCollection>(
        API_ADDRESS + "/api/Tags",
        { headers: this._authorize.getAuthHeader() }
        )
      .pipe(
        map((response: TagCollection) => {
          let collection: TagCollection = [];
            for(let tag of response) {
              collection.push(new Tag(tag));
            }
          return collection;
        }, error => { console.error(error); })
      )
  }
}
