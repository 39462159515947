import { Component, OnInit } from '@angular/core';
import { BookService } from 'src/app/services/Api/book.service';
import { Book } from 'src/app/models/Products/Book';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-book',
  templateUrl: './add-book.component.html',
  styleUrls: ['./add-book.component.css'],
  providers: [BookService]
})
export class AddBookComponent implements OnInit {
  book: Book;
  bookCreateForm: FormGroup;

  constructor(private _bookService: BookService) { }

  ngOnInit() {
    this.formHandler();
  }

  formHandler(): void {
    this.bookCreateForm = new FormGroup({
      title: new FormControl('', [Validators.required, Validators.minLength(5)]),
      author: new FormControl('', [Validators.required]),
      productPrice: new FormControl('', [Validators.required]),
      stock: new FormControl({value: 1, disabled: false}, [Validators.required]),
      isShow: new FormControl(),
      productImage: new FormControl('', [Validators.required]),
      productYoutubeVideo: new FormControl('', Validators.required),
      productDescription: new FormControl('', [Validators.required])
    });
  }

  private afterCallback(response: any): void {
    console.log(response);
  }

  CreateBook(): void {
    console.log(this.bookCreateForm.value);
    this.book = new Book(this.bookCreateForm.value);
    this._bookService.CreateBook(this.book, this.afterCallback);
  }

  cancel(): void {
    console.log('cancel');
  }
}
