import { IUser } from "./IUser";

export class User {
  id?: string;
  userName: string;
  inviteCode: string;
  enableWallets: boolean;
  twoFactorEnabled: boolean;
  creationTime: Date;

  constructor(user?: IUser) {
    if (user !== null) Object.assign(this, user);

    if (user.creationTime !== null)
      this.creationTime = new Date(user.creationTime);
  }
}
