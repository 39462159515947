import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingpageComponent } from "./landingpage/landingpage.component";
import { RegistrationPageComponent } from "./RegistrationPage/RegistrationPage.component";
import { UserAreaComponent } from "./UserArea/UserArea.component";
import { NotFoundComponent } from "./NotFound/NotFound.component";
import { PreRegistratedUsersComponent } from "./UserArea/_InnerPages/PreRegistratedUsers/PreRegistratedUsers.component";
import { AuthorizeGuard } from "./_guards/authhorize.guard";
import { AdminGuard } from "./_guards/admin.guard";
import { UsersComponent } from "./UserArea/_InnerPages/Users/Users.component";
import { LandingpageModifyComponent } from "./UserArea/_InnerPages/landingpage-modify/landingpage-modify.component";
import { AddArticleComponent } from "./UserArea/_InnerPages/Article/add-article/add-article.component";
import { ArticlesComponent } from "./UserArea/_InnerPages/Article/articles/articles.component";
import { ArticleFullViewComponent } from "./UserArea/_InnerPages/Article/article-full-view/article-full-view.component";
import { TagContentsComponent } from "./UserArea/_InnerPages/Tag/tag-contents/tag-contents.component";
import { EditArticleComponent } from "./UserArea/_InnerPages/Article/edit-article/edit-article.component";
import { WalletComponent } from "./UserArea/_InnerPages/Wallet/wallet/wallet.component";
import { SettingsComponent } from "./UserArea/_InnerPages/settings/settings.component";
import { SettingsResolver } from "./_resolvers/settings.resolver";
import { SettingsPreventUnsavedChanges } from "./_guards/settings-prevent-unsaved-changes.guard";
import { UserWalletsResolver } from "./_resolvers/user-wallets.resolver";
import { LandingpageResolver } from "./_resolvers/landingpage.resolver";
import { LandingpagePreventUnsavedChanges } from "./_guards/landingpage-prevent-unsaved-changes.guard copy";
import { PublicationsShopComponent } from "./UserArea/_InnerPages/Publications/publications-shop/publications-shop.component";
import { ProductsResolver } from "./_resolvers/products.resolver";
import { UserTransferListResolver } from "./_resolvers/user-transfer-list.resolver";
import { ToolsComponent } from "./UserArea/_InnerPages/tools/tools.component";
import { TrainingsComponent } from "./UserArea/_InnerPages/trainings/trainings.component";
import { AddBookComponent } from "./UserArea/_InnerPages/add-book/add-book.component";
import { AddEbookComponent } from "./UserArea/_InnerPages/add-ebook/add-ebook.component";
import { OrdersComponent } from "./UserArea/_InnerPages/orders/orders.component";
import { LibraryAdminComponent } from "./UserArea/_InnerPages/library-admin/library-admin.component";
import { ProfileMeComponent } from "./UserArea/_InnerPages/profile-me/profile-me.component";
import { TwoFactorAuthenticationComponent } from "./UserArea/_InnerPages/two-factor-authentication/two-factor-authentication.component";
import { EnableAuthenticatorComponent } from "./UserArea/_InnerPages/two-factor-authentication/enable-authenticator/enable-authenticator.component";
import { ResetAuthenticatorComponent } from "./UserArea/_InnerPages/two-factor-authentication/reset-authenticator/reset-authenticator.component";

const routes: Routes = [
  { path: "", redirectTo: "landing", pathMatch: "full" },
  {
    path: "landing",
    component: LandingpageComponent,
    pathMatch: "full",
    resolve: { landing: LandingpageResolver },
  },
  {
    path: "registration",
    component: RegistrationPageComponent,
    pathMatch: "full",
  },
  {
    path: "user-area",
    component: UserAreaComponent,
    canActivate: [AuthorizeGuard],
    children: [
      {
        path: "preregistrated",
        component: PreRegistratedUsersComponent,
        canActivate: [AdminGuard],
      },
      { path: "users", component: UsersComponent, canActivate: [AdminGuard] },
      {
        path: "landingpage",
        component: LandingpageModifyComponent,
        canActivate: [AdminGuard],
        canDeactivate: [LandingpagePreventUnsavedChanges],
        resolve: { landing: LandingpageResolver },
      },
      {
        path: "articles/add",
        component: AddArticleComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "articles",
        component: ArticlesComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: "articles/:slug",
        component: ArticleFullViewComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: "articles/edit/:slug",
        component: EditArticleComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "tags/:slug",
        component: TagContentsComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: "wallets",
        component: WalletComponent,
        canActivate: [AuthorizeGuard],
        resolve: { wallets: UserWalletsResolver },
      },
      {
        path: "settings",
        component: SettingsComponent,
        canActivate: [AdminGuard],
        resolve: { settings: SettingsResolver },
        canDeactivate: [SettingsPreventUnsavedChanges],
      },
      {
        path: "tools",
        component: ToolsComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: "trainings",
        component: TrainingsComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: "publications",
        component: PublicationsShopComponent,
        canActivate: [AuthorizeGuard],
        resolve: { products: ProductsResolver },
      },
      {
        path: "add-book",
        component: AddBookComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "add-ebook",
        component: AddEbookComponent,
        canActivate: [AdminGuard],
      },
      { path: "orders", component: OrdersComponent, canActivate: [AdminGuard] },
      {
        path: "library-admin",
        component: LibraryAdminComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "profile",
        component: ProfileMeComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: "two-factor-authentication",
        component: TwoFactorAuthenticationComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: "enable-authenticator",
        component: EnableAuthenticatorComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: "reset-authenticator",
        component: ResetAuthenticatorComponent,
        canActivate: [AuthorizeGuard],
      },
    ],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
