import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { UserStorageService } from 'src/app/services/Api/user-storage.service';
import { UserCollection } from 'src/app/models/collections';
import * as M from 'materialize-css';
import { User } from 'src/app/models/User';
import { IUserFilter } from 'src/app/models/Filters/IUserFilter';

@Component({
  selector: 'app-user-auto-select',
  templateUrl: './user-auto-select.component.html',
  styleUrls: ['./user-auto-select.component.css'],
  providers: [UserStorageService]
})
export class UserAutoSelectComponent implements OnInit {
  users: UserCollection;
  field: M.Autocomplete;
  toolTip: M.Tooltip;
  @ViewChild('inputText', {static: true}) inputText: ElementRef<HTMLInputElement>;
  @Output() selectedUser = new EventEmitter<User>();

  @Input() filter: IUserFilter = {} as IUserFilter;

  constructor(private _userService: UserStorageService) { }

  ngOnInit() {
    this.getUsers();
  }

  selectUser(): void {
    const selectedUserName = this.inputText.nativeElement.value;
    let result: User[] = this.users.filter((user: User) => {
      return user.userName === selectedUserName;
    });

    if(result.length > 0) this.selectedUser.emit(result[0]);
  }

  private inputFieldInit(users: UserCollection): void {
    const data: any = {};
    for(let user of users) {
      data[user.userName] = null;
    }
    let input = document.getElementById('user-autoselect-input');
    this.field = M.Autocomplete.init(input, {
      data: data,
    });
  }

  private getUsers(): void {
    this._userService.GetUsers(this.filter)
      .subscribe((response: UserCollection) => {
        this.users = response;
        this.inputFieldInit(this.users);
      });
  }

}
