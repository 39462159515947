import { IUser } from './IUser';
import { TagCollection } from './collections';
import { Tag } from './Tag';
import { User } from './User';

export class Article {
    id: number;
    title: string;
    author: IUser;
    slug: string;
    preview: string;
    body: string;
    published: boolean = false;
    tags: TagCollection = [];
    creationTime: Date;
    modifiedDate: Date;
    publishedDate: Date;

    constructor(article: Article = null) {
        if(article !== null) {
            Object.assign(this, article);

            if(article.author !== null) {
                this.author = new User(article.author);
            }

            this.tags = [];
            if(article.tags.length !== 0) {
                for(let tag of article.tags) {
                    this.tags.push(new Tag(tag));
                }
            }

            if(article.creationTime !== null)
               this.creationTime = new Date(article.creationTime);

            if(article.modifiedDate !== null)
               this.modifiedDate = new Date(article.modifiedDate);

            if(article.publishedDate !== null)
               this.publishedDate = new Date(article.publishedDate);   
        }               
    }
}
