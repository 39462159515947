import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { API_ADDRESS } from '../../app.component';
import { ILandingPage } from 'src/app/models/ILandingPage';
import { Observable } from 'rxjs';
import { AuthorizeService } from './Authorize.service';

@Injectable({
  providedIn: 'root'
})
export class LandingpageService {

  private _httpOptions;

  constructor(
    private _http: HttpClient,
    private _authorize: AuthorizeService
    ) {
    this._httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
   }

   getLandingPage() : Observable<ILandingPage> {
     return this._http.get<ILandingPage>(API_ADDRESS + "/api/landingpage");
   }

   update(landingPage: ILandingPage) : Observable<any> {
     return this._http.put<any>(
       API_ADDRESS + "/api/landingpage/update", 
       landingPage,
       { headers: this._authorize.getAuthHeader() }
       );
   }


}
