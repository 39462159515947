import { Injectable } from '@angular/core';
import * as M from 'materialize-css';

@Injectable({
  providedIn: 'root'
})
export class MaterializeInitService {

  modals: M.Modal[];

  constructor() { }

  Sidenav(): void {
    var elems = document.querySelectorAll('.sidenav');
    M.Sidenav.init(elems);
  };

  CharacterCounter(element: HTMLElement): void {
    M.CharacterCounter.init(element);
  }

  ScrollSpy(): void {
    var elems = document.querySelectorAll('.scrollspy');
    M.ScrollSpy.init(elems);
  };

  Dropdown(): void {
    var elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, {
        constrainWidth: false
    });
  };

  DropdownInit(selector: string): M.Dropdown {
    var elems = document.querySelector(selector);
    return M.Dropdown.init(elems, {
        constrainWidth: false
    });
  };
  

  Modal(): void {
    var elems = document.querySelectorAll('.modal');
    this.modals = M.Modal.init(elems);
  };

  Collapsibile(): void {
    // collapsibile
    var elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems);
  }
}
