import { Injectable } from '@angular/core';
import { Config } from 'src/app/enums/Config';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageFields } from 'src/app/enums/LocalStorageFields.enum';
import { User } from 'src/app/models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {
  constructor() { }

  private readAuthToken() : string {
    return localStorage.getItem(LocalStorageFields.auth_token);
  }

  getAuthHeader() : HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + this.readAuthToken()
    });
  }

}
