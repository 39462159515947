import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/Api/Auth.service";
import { User } from "src/app/models/User";

@Component({
  selector: "app-two-factor-authentication",
  templateUrl: "./two-factor-authentication.component.html",
  styleUrls: ["./two-factor-authentication.component.css"],
  providers: [AuthService],
})
export class TwoFactorAuthenticationComponent implements OnInit {
  constructor(private _authService: AuthService) {}

  enabled: boolean;

  ngOnInit() {
    this.enabled = this.get2FAEnabled();
    this.userDataRefresh();
  }

  userDataRefresh() {
    this._authService.RefreshCurrentUserData().subscribe(
      (response: User) => {
        this.enabled = response.twoFactorEnabled;
      },
      (error) => console.error(error)
    );
  }

  get2FAEnabled(): boolean {
    return this._authService.CurrentUser().twoFactorEnabled;
  }
}
