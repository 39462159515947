import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TagStorageService } from 'src/app/services/Api/tag-storage.service';
import { ArticleCollection } from 'src/app/models/collections';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleStorageService } from 'src/app/services/Api/article-storage.service';
import { IArticle } from 'src/app/models/IArticle';
import { ITag } from 'src/app/models/ITag';
import { Article } from 'src/app/models/Article';
import { Tag } from 'src/app/models/Tag';
import { ToastService } from 'src/app/services/Toast.service';

@Component({
  selector: 'app-tag-contents',
  templateUrl: './tag-contents.component.html',
  styleUrls: ['./tag-contents.component.css'],
  providers: [TagStorageService, ToastService]
})
export class TagContentsComponent implements OnInit {
  articleList: ArticleCollection = [];
  tag: Tag;
  showTag: boolean = false;
  showArticleList: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _tagStorage: TagStorageService,
    private _toast: ToastService
  ) { }

  ngOnInit() {
    const slug = this._route.snapshot.paramMap.get('slug');
    this.getTag(slug);
    this.getTagContent(slug);
  }

  private getTagContent(slug: string) : void {
    this._tagStorage.GetTagContents(slug)
      .subscribe(response => {
        this.articleList = response;
        this.showArticleList = true;
      }, error => this._toast.dropDanger("Címkéhez való tartalom lekérdezése sikertelen."));
  }

  private getTag(slug: string) : void {
    this._tagStorage.GetBySlug(slug)
      .subscribe((result: Tag) => {
        console.log(result);
        this.tag = result;
        this.showTag = true;
      }, (error) => { console.error(error); });
  }

}
