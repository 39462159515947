import { ITag } from './ITag';

export class Tag {
    name: string;
    slug?: string;
    creationTime?: Date;

    constructor(tag?: ITag) {
        if(tag !== null) 
            Object.assign(this, tag);
        
        // if(tag.creationTime !== null)
        //     this.creationTime = new Date(tag.creationTime);
    }
}
