import { Component, OnInit } from '@angular/core';
import { States } from 'src/app/enums/States';
import { ToastService } from 'src/app/services/Toast.service';
import { ClipboardService } from 'ngx-clipboard'
import { AuthService } from 'src/app/services/Api/Auth.service';
import { IUsernameResponse } from 'src/app/models/IUsernameResponse';
import { ActivatedRoute }       from '@angular/router';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { IGenerateTelegramIDRequest } from 'src/app/models/IGenerateTelegramIDRequest';

@Component({
  selector: 'app-telegram-id',
  templateUrl: './telegram-id.component.html',
  styleUrls: ['../landingpage.component.css', './telegram-id.component.css']
})
export class TelegramIDComponent implements OnInit {
  state: States = States.base;
  states: any = States;
  username: string = "";
  serverSendingUsername: string = "";
  invitingPersonUserName: string;
  invitingPersonShow: boolean = false;
  private _invitationCode: string;

  constructor(
    private _toast: ToastService,
    private route: ActivatedRoute,    
    private _clipboardService: ClipboardService,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this._getInviteCodeFromUri();
  }

  _getInviteCodeFromUri() : void {
    const inviteCode: Observable<string> = this.route
      .queryParamMap
      .pipe(map(params => params.get('invite') || 'None'));

    inviteCode.subscribe((result: string) => {
      if(result !== "None") {
        this._invitationCode = result;
        this._auth.checkInviteCode(result)
          .subscribe((response) => {
            this.invitingPersonUserName = response.username;
            this.invitingPersonShow = true;
            this._toast.dropSuccess("Érvényes meghívókód!");
          }, (error) => {
            this._toast.dropDanger("A meghívókódot nem sikerült érvényesíteni!");
          });
      }
    });
  }

  generateTelegramID() : void {
    this.state = States.load;
    this._auth.getTelegramID({
      username: this.username,
      invitationCode: this._invitationCode
    } as IGenerateTelegramIDRequest)
      .subscribe((result: IUsernameResponse) => {
        this.serverSendingUsername = result.username;
        setTimeout(() => {
          this.state = States.done;
        }, 2500);
      }, (error) => {
        this._toast.dropDanger("Telegram ID generálása sikertelen.");
        this.state = States.done;
      });
            
  }

  copyToClipboard() : void {
    let copiedText = this.serverSendingUsername;
    copiedText = copiedText.trim();
    this._clipboardService.copyFromContent(copiedText);
    this._toast.dropPrimary('Username copied to clipboard');
  }

}
