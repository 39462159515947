import { Component, OnInit, Input } from '@angular/core';
import { CommentCollection } from 'src/app/models/collections';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.css']
})
export class CommentSectionComponent implements OnInit {

  @Input() comments: CommentCollection;
  @Input() articleAuthor: User;

  constructor() { }

  ngOnInit() {
  }

}
