import { Component, OnInit, Input } from '@angular/core';
import { TagCollection } from 'src/app/models/collections';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tag-box-view',
  templateUrl: './tag-box-view.component.html',
  styleUrls: ['./tag-box-view.component.css']
})
export class TagBoxViewComponent implements OnInit {

  @Input() tagCollection: TagCollection;

  constructor(
    private _route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const slug = this._route.snapshot.paramMap.get('slug');
  }

}
