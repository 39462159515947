import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { IUsernameRequest } from "../../models/IUsernameRequest";
import { HttpHeaders } from "@angular/common/http";
import { API_ADDRESS } from "../../app.component";
import { IRegistrationRequest } from "src/app/models/IRegistrationRequest";
import { ILoginRequest } from "src/app/models/ILoginRequest";
import { map, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { IPreRegistratedUser } from "src/app/models/IPreRegistratedUser";
import { AuthorizeService } from "./Authorize.service";
import { IDecodedJwtToken } from "src/app/models/IDecodedJwtToken";
import { LocalStorageFields } from "src/app/enums/LocalStorageFields.enum";
import { IGenerateTelegramIDRequest } from "src/app/models/IGenerateTelegramIDRequest";
import { RedirectService } from "../Redirect.service";
import { User } from "src/app/models/User";
import { UserStorageService } from "./user-storage.service";
import { ILoginRequestWithTwoFa } from "../../models/ILoginRequestWithTwoFa";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  jwtHelper = new JwtHelperService();
  decodedToken: IDecodedJwtToken = null;
  private _currentUser: User = null;

  private _httpOptions;

  constructor(
    private _http: HttpClient,
    private _authorize: AuthorizeService,
    private _redirect: RedirectService,
    private _userService: UserStorageService
  ) {
    this.decodedToken = this.readTokenFromStorage();
    this._httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  RefreshCurrentUserData(): Observable<User> {
    this.readCurrentUser();
    return this._userService.GetById(this._currentUser.id).pipe(
      tap((response) => {
        this.Persistent(response);
      })
    );
  }

  /**
   * To copy local storage user
   * @param user
   */
  Persistent(user: User): void {
    localStorage.setItem(LocalStorageFields.user, JSON.stringify(user));
  }

  CurrentUser(): User | null {
    if (this._currentUser === null) {
      this.readCurrentUser();
      return this._currentUser;
    }

    return this._currentUser;
  }

  private readCurrentUser() {
    this._currentUser = JSON.parse(
      localStorage.getItem(LocalStorageFields.user)
    );
  }

  Logout(): void {
    this.removeToken();
    this.removeUser();
    this._redirect.ToLanding();
  }

  enabled(user: IPreRegistratedUser): Observable<any> {
    let request: any = [];
    request.push(user);
    const httpOptions: HttpHeaders = this._authorize.getAuthHeader();
    return this._http.post<any>(
      API_ADDRESS + "/api/auth/createregkeys",
      request,
      { headers: httpOptions }
    );
  }

  checkInviteCode(inviteCode: string): Observable<any> {
    return this._http.get<any>(
      API_ADDRESS + "/api/Auth/inviteCode/" + inviteCode
    );
  }

  getTelegramID(request: IGenerateTelegramIDRequest): Observable<any> {
    return this._http.post<any>(
      API_ADDRESS + "/api/auth/pre-registration",
      request,
      this._httpOptions
    );
  }

  readTokenFromStorage(): IDecodedJwtToken {
    const token = localStorage.getItem(LocalStorageFields.auth_token);
    if (token === null) {
      return null;
    }
    return this.jwtHelper.decodeToken(token);
  }

  checkValidRegKey(regKey: string): Observable<HttpResponse<any>> {
    return this._http.get<any>(
      API_ADDRESS + "/api/auth/checkvalidregkey/" + regKey,
      { observe: "response" }
    );
  }

  registration(request: IRegistrationRequest): Observable<any> {
    return this._http.post<any>(
      API_ADDRESS + "/api/auth/registration",
      request
    );
  }

  login(request: ILoginRequest): Observable<any> {
    return this._http.post<any>(API_ADDRESS + "/api/auth/login", request).pipe(
      map((response: any) => {
        const user = response;
        localStorage.setItem(LocalStorageFields.auth_token, user.token);
        localStorage.setItem(
          LocalStorageFields.user,
          JSON.stringify(response.user)
        );
        this.decodedToken = this.jwtHelper.decodeToken(user.token);
      })
    );
  }

  loginWith2FA(request: ILoginRequestWithTwoFa): Observable<any> {
    return this._http
      .post<any>(API_ADDRESS + "/api/auth/login2FA", request)
      .pipe(
        map((response: any) => {
          const user = response;
          localStorage.setItem(LocalStorageFields.auth_token, user.token);
          localStorage.setItem(
            LocalStorageFields.user,
            JSON.stringify(response.user)
          );
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
        })
      );
  }

  loggedIn(): boolean {
    const token = localStorage.getItem(LocalStorageFields.auth_token);
    return !this.jwtHelper.isTokenExpired(token);
  }

  removeToken(): void {
    localStorage.removeItem(LocalStorageFields.auth_token);
  }

  removeUser(): void {
    localStorage.removeItem(LocalStorageFields.user);
  }
}
