import { Component, OnInit } from "@angular/core";
import { TwoFactorService } from "src/app/services/Api/two-factor.service";
import { IFormInputValidationState } from "src/app/models/IFormInputValidationState";
import { RedirectService } from "../../../../services/Redirect.service";
import { AuthService } from "../../../../services/Api/Auth.service";

@Component({
  selector: "app-reset-authenticator",
  templateUrl: "./reset-authenticator.component.html",
  styleUrls: ["./reset-authenticator.component.css"],
  providers: [TwoFactorService, RedirectService, AuthService],
})
export class ResetAuthenticatorComponent implements OnInit {
  validationState: IFormInputValidationState = {} as IFormInputValidationState;

  constructor(
    private _2FA: TwoFactorService,
    private _redirectService: RedirectService,
    private _authService: AuthService
  ) {}

  ngOnInit() {}

  reset(): void {
    this._2FA.ResetAuthenticatorKey().subscribe(
      (response) => {
        console.log(response);
        this.validationState.isValid = true;
        this.validationState.message = response.message;
        this._authService.RefreshCurrentUserData().subscribe(
          (reponse) => {
            this._redirectService.ToTwoFactorAuthentication();
          },
          (error) => {}
        );
      },
      (error) => {
        this.validationState.isValid = false;
        this.validationState.message = error.error.message;
      }
    );
  }
}
