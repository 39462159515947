import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISettings } from 'src/app/models/ISettings';
import { API_ADDRESS } from 'src/app/app.component';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private _http: HttpClient) { }

  Current() : Observable<ISettings> {
    return this._http.get<ISettings>(API_ADDRESS + "/api/Settings");
  }

  Create(settings: ISettings) : Observable<any> {
    return this._http.post(API_ADDRESS + "/api/Settings", settings);
  }
}
